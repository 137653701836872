<template>
  <div class="weui-tab">
    <div class="weui-tab__bd public-container">
      <div
        class="
          public-areas public-area-user-list public-area-user-account
          margin-bottom-10 margin-top-10
        "
      >
        <div class="weui-cells margin-top-0">
          <a class="weui-cell weui-cell_access" href="javascript:;">
            <div class="weui-cell__bd">
              <p>头像</p>
            </div>
            <div class="weui-cell__ft">
              <img
                class="avatar"
                id="avatar-preview"
                :src="avatarUrl"
              />
              <form
                id="edit-avatar"
                method="post"
                enctype="multipart/form-data"
              >
                <input
                  type="file"
                  class="file-avatar"
                  id="file-avatar"
                  accept="image/gif, image/jpeg, image/png"
                  name="avatar"
                  value="上传"
                />
              </form>
            </div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'editNickname' })"
          >
            <div class="weui-cell__bd">
              <p>昵称</p>
            </div>
            <div class="weui-cell__ft">{{nickName}}</div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'editMobile' })"
          >
            <div class="weui-cell__bd">
              <p>手机</p>
            </div>
            <div class="weui-cell__ft" v-if="mobile">{{mobile}}</div>
            <div class="weui-cell__ft" v-else>未绑定</div>
          </a>
          <!-- <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'editEmail' })"
          >
            <div class="weui-cell__bd">
              <p>邮箱</p>
            </div>
            <div class="weui-cell__ft" v-if="email">{{email}}</div>
            <div class="weui-cell__ft" v-else>未绑定</div>
          </a> -->
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'editAlipay' })"
          >
            <div class="weui-cell__bd">
              <p>支付宝</p>
            </div>
            <div class="weui-cell__ft" v-if="alipayAcc">{{alipayAcc}}</div>
            <div class="weui-cell__ft" v-else>未绑定</div>
          </a>
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'editName' })"
          >
            <div class="weui-cell__bd">
              <p>姓名</p>
            </div>
            <div class="weui-cell__ft" v-if="name">{{name}}</div>
            <div class="weui-cell__ft" v-else>未填写</div>
          </a>
        </div>
      </div>

      <div class="public-areas public-area-user-list margin-bottom-10">
        <div class="weui-cells margin-top-0">
          <a
            class="weui-cell weui-cell_access"
            @click="$router.push({ name: 'setPassWord' })"
          >
            <div class="weui-cell__bd">
              <p>修改密码</p>
            </div>
            <div class="weui-cell__ft"></div>
          </a>
        </div>
      </div>
    </div>
    <tabbar :selectedVal="'users'"></tabbar>
  </div>
</template>

<script>
import { getUserInfo } from '../api/index'
import { getParams } from '../utils/index'
import tabbar from '../components/tabbar/index.vue'
import wxauth from '../mixin/wxauth'
export default {
  mixins: [wxauth],
  name: "account",
  components: {
    tabbar
  },
  data () {
    return {
      nickName: '',
      avatarUrl: './assets/img/face.png', //	头像url
      mobile: '',
      name: '',
      email: '',
      alipayAcc: '',
    }
  },
  created () {
    // this.dologinApi()
    // this.wxAuthInit()
  },
  mounted () {
    console.log(getParams('name'))
  },
  methods: {
    // 提交用户信息
    async doGetUserInfo () {
      let params = {
        param: {}
      }
      let res = {}
      try {
        res = await getUserInfo(params)
        this.$toast.loading('加载中', { duration: 0 });
        if (res.code === '0000') {
          this.$toast.hide()
          const result = res.result
          this.nickName = result.nickName
          this.mobile = result.mobile
          this.name = result.name
          this.email = result.email
          this.alipayAcc = result.alipayAcc
          if (result.avatarUrl) {
            this.avatarUrl = result.avatarUrl
          }
          this.storageSet('SURVEY_mobile', result.mobile)
          this.storageSet('SURVEY_name', result.name)
          this.storageSet('SURVEY_nickName', result.nickName)
          this.storageSet('SURVEY_email', result.email)
          this.storageSet('SURVEY_alipayAcc', result.alipayAcc)
        } else {
          this.$toast.hide()
          console.log('活动火爆，请稍后重试')
        }
      } catch (err) {
        this.$toast.hide()
        console.log('err:', err)
      }
    },
    storageSet(key, val) {
      if (!key || !val) return false
      localStorage.setItem(key, val)
    },
    doWxAuthCb (flag) {
      console.log('cb', flag)
      this.doGetUserInfo()
    }
  }
};

</script>

<style lang="css" scoped>
#edit-avatar {
  display: block;
  width: 1.707rem;
  height: 1.707rem;
  position: absolute;
  top: 0;
  opacity: 0;
}
.public-area-user-account .file-avatar {
  display: block;
}
.public-container{
  margin: unset;
}
</style>
